<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="หลักสูตรวิชาชีพระยะสั้น : จากระบบ ศธ.02 "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
              <v-form ref="updateshort_courseform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="6" class="pa-1">
                    <v-autocomplete
                      v-model="type_courseSelect"
                      outlined
                      label="ประเภทวิชา"
                      :items="subject_types"
                      item-text="subject_typeName"
                      item-value="subject_typeID"
                      @change="short_courseSearch()"
                      require
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:item="{ item }">
                        <div>
                          <div>
                            {{ item.subject_typeID }} :
                            {{ item.subject_typeName }}
                          </div>
                          <div></div>
                        </div>
                      </template>
                    </v-autocomplete>
                  
                  </v-col>
                  <v-col cols="4" class="pa-1"
                    ><v-text-field
                      outlined
                      label="กำหนดรหัสประเภทวิชา"
                      v-model="editshort_course.short_courseSubJectID"
                      require
                      :rules="[v => !!v || '']"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2" class="pa-1"
                    ><v-btn block color="primary" @click="short_courseUpdate()"
                      >บันทึก</v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>
              <v-form ref="updateshort_courseBranchform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="6" class="pa-1">
                    <v-autocomplete
                      v-model="short_courseGBsSelect"
                      outlined
                      label="สาขาวิชา"
                      :items="short_courseGBs"
                      item-text="short_courseSTBranch"
                      item-value="short_courseSTBranch"
                      @change="short_courseSTBSearch()"
                      require
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:item="{ item }">
                        <div>
                          <div style="font-weight: bold;">
                            {{ item.short_courseSTBranchID }} :
                            {{ item.short_courseSTBranch }}
                          </div>
                          <div>
                            {{ item.short_courseSubJectID }} :{{
                              item.subject_typeName
                            }}
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="pa-1"
                    ><v-text-field
                      outlined
                      label="กำหนดรหัสสาขาวิชา"
                      v-model="editshort_courseSTB.short_courseSTBranchID"
                      require
                      :rules="[v => !!v || '']"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="2" class="pa-1"
                    ><v-btn
                      block
                      color="primary"
                      @click="short_courseSTBUpdate()"
                      >บันทึก</v-btn
                    ></v-col
                  >
                </v-row>
              </v-form>
            </v-col>

            <v-col cols="12" lg="4" class="text-right">
              <v-btn
                block
                large
                right
                depressed
                color="primary"
                @click.native="short_courseAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                large
                right
                depressed
                color="info"
                @click.native="short_courseQueryAll()"
              >
                <v-icon>mdi-content-paste</v-icon>แสดงรายการ
              </v-btn>

             <!--  <v-btn
                block
                large
                right
                depressed
                color="warning"
                @click.native="man_powerCSV()"
              >
                <v-icon>mdi-file-plus</v-icon>นำเข้าข้อมูลไฟล์
              </v-btn> -->
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="short_courses"
          :search="search"
        >
          <template v-slot:item.short_courseStatus="{ item }">
            <v-icon v-if="item.short_courseStatus === '1'" color="green">
              mdi-lightbulb-on
            </v-icon>

            <v-icon v-else>
              mdi-lightbulb
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="short_courseEdit(item.short_courseIDNo)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="short_courseDelete(item.short_courseIDNo)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addshort_coursedialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addshort_coursedialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลสาขาวิชาเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addshort_courseform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-select
                        label="ประเภทวิชา"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeID"
                        v-model="addshort_course.short_courseSubJectID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                      {{ addshort_course.short_courseSubJectID }}
                    </v-flex>
                    <v-flex md4>
                      <v-select
                        label="สาขาวิชาที่เกี่ยวข้อง"
                        :items="rate_work_course_stds"
                        item-text="branch_name_th"
                        item-value="id_course_branch"
                        v-model="addshort_course.short_courseSTBranchID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                      {{ addshort_course.short_courseSTBranchID }}
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        label="รหัสสาขาวิชา"
                        v-model="addshort_course.short_courseSTBranch"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        label="ชื่อหลักสูตระยะสั้น"
                        v-model="addshort_course.short_courseGroupName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="ช่วงระยะเวลา"
                        v-model="addshort_course.short_courseTimeST"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสหลักสูตรระยะสั้น"
                        v-model="addshort_course.short_courseID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อหลักสูตรระยะสั้น"
                        v-model="addshort_course.short_courseName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-switch
                        v-model="addshort_course.short_courseStatus_sw"
                        label="เปิดใช้งานหลักสูตร"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addshort_coursedialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addshort_courseSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteshort_coursedialog -->
      <v-layout>
        <v-dialog v-model="deleteshort_coursedialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลสาขาวิชาเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteshort_courseform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลสาขาวิชาเอก
                          {{ editshort_course.branch_sub_name_th }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteshort_coursedialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteshort_courseSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editshort_coursedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editshort_coursedialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editshort_courseform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-select
                        label="ประเภทวิชา"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeID"
                        v-model="editshort_course.short_courseSubJectID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-select>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        label="รหัสสาขาวิชา"
                        v-model="editshort_course.short_courseSTBranchID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        label="ชื่อสาขาวิชา"
                        v-model="editshort_course.short_courseSTBranch"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อหลักสูตร"
                        v-model="editshort_course.short_courseGroupName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="ช่วงระยะเวลา"
                        v-model="editshort_course.short_courseTimeST"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสหลักสูตรระยะสั้น"
                        v-model="editshort_course.short_courseID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        label="ชื่อหลักสูตรระยะสั้น"
                        v-model="editshort_course.short_courseName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-switch
                        v-model="editshort_course.short_courseStatus_sw"
                        label="เปิดใช้งานหลักสูตร"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editshort_coursedialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editshort_courseSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model man_powerCSVdialog -->
      <v-layout>
        <v-dialog v-model="man_powerCSVdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="man_powerCSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/admin/short_course_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV สาขาวิชา"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="man_powerCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addshort_coursedialog: false,
      editshort_coursedialog: false,
      deleteshort_coursedialog: false,
      man_powerCSVdialog: false,
      file2: null,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      short_courses: [],
      addshort_course: {},
      editshort_course: {},
      editshort_courseSTB: {},
      search: "",
      type_courseSelect: "",
      pagination: {},
      headers: [
        { text: "ประเภทวิชา", align: "center", value: "short_courseSubJectID" },
        {
          text: "ชื่อประเภทวิชา",
          align: "center",
          value: "subject_typeName"
        },
        { text: "สาขาวิชา", align: "left", value: "short_courseSTBranchID" },
        { text: "ชื่อสาขาวิชา", align: "left", value: "short_courseSTBranch" },
        {
          text: "กลุ่ม",
          align: "left",
          value: "short_courseGroupName"
        },
        { text: "ช่วงเวลา", align: "left", value: "short_courseTimeST" },
        { text: "รหัสหลักสูตร", align: "left", value: "short_courseID" },
        {
          text: "ชื่อหลักสูตรระยะสั้น",
          align: "left",
          value: "short_courseName"
        },
        {
          text: "สถานะ",
          align: "left",
          value: "short_courseStatus"
        },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      short_coursestatus: [],
      subject_types: [],
      addshort_courseSubJectID: {},
      regions: [],
      region_ena: true,
      rate_work_level_classSelect: [
        { text: "ปวช.", value: "20" },
        { text: "ปวส.", value: "30" }
      ],
      year_courseSelect: [
        { text: "ปวช.62", value: "ปวช.62" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.66", value: "ปวช.66" },
        { text: "ปวช.67", value: "ปวช.67" },
        { text: "ปวช.68", value: "ปวช.68" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวส.63", value: "ปวส.63" },
        { text: "ปวส.64", value: "ปวส.64" },
        { text: "ปวส.65", value: "ปวส.65" },
        { text: "ปวส.66", value: "ปวส.66" },
        { text: "ปวส.67", value: "ปวส.67" },
        { text: "ปวส.68", value: "ปวส.68" }
      ],
      short_courseGBs: [],
      short_courseGBsSelect: "",
      rate_work_course_stds:[],
    };
  },
  async mounted() {
    this.short_courseQueryAll();
    this.subject_typeGroupBranch();
    this.short_courseGroupBranch();
    this.rate_work_course_stdQuery();
  },
  methods: {

   async rate_work_course_stdQuery(){   
      let result = await this.$http
        .post("rate_work_course_std.php", {
          ApiKey: this.ApiKey
        })       
      this.rate_work_course_stds = result.data;      
   },

    async short_courseUpdate() {
      if (this.$refs.updateshort_courseform.validate()) {
        this.editshort_course.ApiKey = this.ApiKey;
        this.editshort_course.short_courseSubJect = this.type_courseSelect;
        
        let result = await this.$http.post(
          "short_course.updateIdtypeCourse.php",
          this.editshort_course
        );
        ;
        if (result.data.status == true) {
          this.short_course = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.short_courseQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.short_courseQueryAll();
      }
    },
    async short_courseSTBUpdate() {
      if (this.$refs.updateshort_courseBranchform.validate()) {
        this.editshort_courseSTB.ApiKey = this.ApiKey;
        this.editshort_courseSTB.short_courseSTBranch = this.short_courseGBsSelect;
     
        let result = await this.$http.post(
          "short_course.updateshort_courseSTBranchID.php",
          this.editshort_courseSTB
        );
        ;
        if (result.data.status == true) {
          this.short_course = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.short_courseQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.short_courseQueryAll();
      }
    },

    async short_courseQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("short_course.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.short_courses = result.data;
      ;
    },

    async short_courseGroupBranch() {
      this.loading = true;
      let result = await this.$http
        .post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranchGroup: "OK"
        })
        .finally(() => (this.loading = false));
      this.short_courseGBs = result.data;
    },

    async short_courseSearch() {
      this.loading = true;
      let result = await this.$http
        .post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSubJectID: this.type_courseSelect
        })
        .finally(() => (this.loading = false));
      this.short_courses = result.data;
    },
    async short_courseSTBSearch() {
      this.loading = true;
      let result = await this.$http
        .post("short_course.php", {
          ApiKey: this.ApiKey,
          short_courseSTBranch: this.short_courseGBsSelect
        })
        .finally(() => (this.loading = false));
      this.short_courses = result.data;
      ;
    },

    async subject_typeGroupBranch() {
      this.loading = true;
      let result = await this.$http
        .post("subject_type.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.subject_types = result.data;
    },

    async short_courseAdd() {
      this.addshort_course = {};
      this.addshort_coursedialog = true;
    },
    async addshort_courseSubmit() {
      if (this.$refs.addshort_courseform.validate()) {
        this.addshort_course.ApiKey = this.ApiKey;
        if (this.addshort_course.short_courseStatus_sw == true) {
          this.addshort_course.subject_typeStatus = "1";
        } else {
          this.addshort_course.subject_typeStatus = "0";
        }

        let result = await this.$http.post(
          "short_course.insert.php",
          this.addshort_course
        );
        if (result.data.status == true) {
          this.short_course = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.short_courseQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.short_courseQueryAll();
        }
        this.addshort_coursedialog = false;
      }
    },
    async short_courseEdit(short_courseIDNo) {
      let result = await this.$http.post("short_course.php", {
        ApiKey: this.ApiKey,
        short_courseIDNo: short_courseIDNo
      });
      this.editshort_course = result.data;
      this.editshort_coursedialog = true;
      
    },
    async editshort_courseSubmit() {
      if (this.$refs.editshort_courseform.validate()) {
        this.editshort_course.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "short_course.update.php",
          this.editshort_course
        );
        if (result.data.status == true) {
          this.short_course = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.short_courseQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editshort_coursedialog = false;
      }
    },
    async short_courseDelete(short_courseIDNo) {
      let result = await this.$http.post("short_course.php", {
        ApiKey: this.ApiKey,
        short_courseIDNo: short_courseIDNo
      });
      this.editshort_course = result.data;
      this.deleteshort_coursedialog = true;
    },
    async deleteshort_courseSubmit() {
      if (this.$refs.deleteshort_courseform.validate()) {
        this.editshort_course.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "short_course.delete.php",
          this.editshort_course
        );
        ;
        if (result.data.status == true) {
          this.short_course = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.short_courseQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleteshort_coursedialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
